export const GA_TRACKING_ID = 'G-72DPE10X8H'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: string) => {
  window.gtag('event', 'page_view', {
    page_path: url,
  })
}

export const adClick = (destination: string) => {
  window.gtag('event', 'ad_click', {
    destination,
  })
}

type GTagEvent = {
  action: string
  category: string
  label: string
  value: number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}
