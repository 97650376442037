import { FC, ReactNode } from 'react'
import Header from '../Header'
import Footer from '../Footer'

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-2 lg:mx-auto my-0">{children}</div>
      <Footer />
    </>
  )
}

export default MainLayout
