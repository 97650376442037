import '../styles/globals.css'
import type { AppProps } from 'next/app'
import MainLayout from '../components/MainLayout'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { pageView } from '../lib/gtag'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageView(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      {process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === 'true' ? (
        <Script
          id="google-analytics"
          strategy={'afterInteractive'}
          src="https://www.googletagmanager.com/gtag/js?id=G-72DPE10X8H"
        />
      ) : null}
      <Script
        id="google-analytics"
        strategy={'afterInteractive'}
        src="https://www.googletagmanager.com/gtag/js?id=G-72DPE10X8H"
      />
      <Script
        id="google-analytics-push"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-72DPE10X8H');
            gtag('config', 'UA-121828085-6');
          `,
        }}
      />
      <MainLayout>
        <Component {...pageProps} />
      </MainLayout>
    </>
  )
}

export default MyApp
