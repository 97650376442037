import { FC } from 'react'
import Link from 'next/link'
import Image from 'next/image'

const Header: FC = () => {
  return (
    <>
      <nav className="mb-6 bg-slate-900">
        <div className="border-slate-700 py-3 border-b">
          <div className="flex items-center mx-2 max-w-4xl lg:mx-auto my-0">
            <div className="flex items-center mr-2">
              <Image
                layout="fixed"
                width="50"
                height="50"
                src="/mkc-transparent-no-text.png"
                alt="Miro Knows Crypto Logo"
              />
            </div>
            <Link href="/">
              <a>
                Miro Knows <span className="font-bold">Crypto</span>
              </a>
            </Link>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
